import React from "react"

import { Layout, Seo } from "../../layout"
import { Card } from "../../components"

export default function IndexPage(props) {
  return (
    <Layout path={props.path}>
      <Seo title="Home" />

      <div className="w-full flex flex-wrap justify-center my-24">
        <Card
          image="/solutions/nesbyen_sykkelkart.jpeg"
          altimage="bike map with qr-code in Nesbyen"
          heading="Hallingdal Rides"
          ingress="Bike region"
          logo="/solutions/logo-hallingdalrides.jpeg"
          altlogo="Logo Hallingdal Rides"
          to="/usecases/hallingdal"
        >
          Six destinations work together to provide some of the best biking in
          Europe! The beautiful valley is full of natural bike trails, family
          friendly flow trails, pump tracks, and also Europes longest machine
          build trail.
        </Card>
        <Card
          image="/solutions/laptop_phone_hafjell_warm.jpeg"
          altimage="Seamless integration on website and app"
          heading="Hafjell"
          ingress="Destination"
          logo="/solutions/logo-hafjell.jpeg"
          altlogo="Logo Hafjell"
          to="/usecases/hafjell"
        >
          Hafjell is well known for its ski resort and for the great bike park
          that hosted the downhill world championship in 2014. Less known is the
          great possibilities for trail biking and other activities in the area
          of Lillehammer!
        </Card>
        <Card
          image="/solutions/vpg_shop.jpeg"
          altimage="Vertical Sport Shop"
          heading="VPG"
          ingress="Sport shop"
          logo="/solutions/vpg_logo3.png"
          altlogo="Logo Vertical Playground"
          to="/usecases/verticalplayground"
        >
          VPG is a sport shop with a history. With the innovative online shop
          they are well known in Oppdal and all over Norway! With Trailguide
          they can reach their target group.
        </Card>
      </div>
    </Layout>
  )
}
